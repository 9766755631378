import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { AssessmentDetails, OrgDetails } from './sections';
import { useStateSelector } from 'src/redux';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { SlideId } from 'src/api/types/wizard';

interface SlideProps {
  onChangeSlide: (id: SlideId) => void;
}

export const Finalise: React.FC<SlideProps> = ({ onChangeSlide }) => {
  const hideAssessment = useStateSelector(
    ({ app }) =>
      app.wizardWorkspace?.intent.type === WizardIntent.workspace &&
      !app.wizardWorkspace.intent.params.createAssessment,
  );

  return (
    <>
      <Poppins px={14} color="davysGrey">
        The values and their default variable names can be modified later in settings
      </Poppins>
      <Spacer $px={34} />
      <div className="fdetails">
        <OrgDetails onChangeSlide={onChangeSlide} />
        {!hideAssessment && <AssessmentDetails onChangeSlide={onChangeSlide} />}
      </div>
      <Spacer $px={34} />
    </>
  );
};
