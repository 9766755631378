import React from 'react';
import styled from 'styled-components';
import { ModifiersTransfer, ProjectedScenario, TransferLimitType } from 'src/api/types';
import Action from './Action';
import { GradientTextAction } from 'src/common';
import { FieldArray, useFormikContext } from 'formik';
import Button from 'src/components/form/Button';
import { Note } from '../../comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  display: grid;
  grid-gap: 10px;
  button[type='submit'] {
    width: 120px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const options = [
  {
    label: 'Limit',
    value: 'TRANSFER_LIMIT',
  },
];

interface TransferModifierProps {
  projectedScenario: ProjectedScenario;
  disabled?: boolean;
  isDirtyOutside?: boolean;
  isInModal?: boolean;
  noResult?: boolean;
}

const TransferModifier: React.FC<TransferModifierProps> = ({ projectedScenario, disabled, isInModal, noResult }) => {
  const { values, handleSubmit, setFieldValue, resetForm, dirty, isSubmitting, isValid } =
    useFormikContext<ModifiersTransfer<TransferLimitType>>();

  const handleAddModifier = () => {
    const newModifier = { type: 'TRANSFER_LIMIT', data: { excess: '', tpc: '' }, note: '' };
    const updatedTransfer = [...values.transfer, newModifier];
    setFieldValue('transfer', updatedTransfer);
  };

  return (
    <Div>
      {/* @ts-ignore */}
      <FieldArray name="transfer">
        {({ form, ...helpers }) => (
          <>
            {values.transfer.map((el, idx) => (
              <Action
                key={el.type + idx}
                idx={idx}
                options={options}
                projectedScenario={projectedScenario}
                onRemove={() => helpers.remove(idx)}
                disabled={disabled}
                noResult={noResult}
              />
            ))}
            {!disabled && values.transfer.length < options.length && (
              <GradientTextAction
                onClick={() => {
                  handleAddModifier();
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Modifier:Add',
                    tags: ['MODIFIERS'],
                  });
                }}
              >
                + Add Limit Modifier
              </GradientTextAction>
            )}
            {values.transfer.length > 0 && <Note idx={0} namePrefix="transfer" disabled={disabled} />}
          </>
        )}
      </FieldArray>
      {!isInModal && dirty && (
        <ButtonContainer>
          <Button
            onClick={() => {
              handleSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Modifier:Save',
                tags: ['MODIFIERS'],
              });
            }}
            primary
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              resetForm();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Modifier:Cancel',
                tags: ['MODIFIERS'],
              });
            }}
            secondary
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </ButtonContainer>
      )}
    </Div>
  );
};

export default TransferModifier;
