import React from 'react';
import { motion } from 'framer-motion';

interface SlideProps {
  isCurrent: boolean;
  children: React.ReactNode;
  slideHeight: number;
}

export const Slide: React.FC<SlideProps> = ({ isCurrent, children, slideHeight }) => {
  return (
    <motion.div
      className="keen-slider__slide h-padding"
      initial={false}
      animate={{
        height: isCurrent ? 'unset' : slideHeight,
      }}
    >
      <motion.div
        animate={{
          scale: isCurrent ? 1 : 0.9,
          opacity: isCurrent ? 1 : 0,
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
