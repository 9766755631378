import Button from 'src/components/form/Button';
import styled, { css } from 'styled-components';
import { ReactComponent as PrevIcon } from 'src/assets/wizzard-btn-chev-prev.svg';
import { ReactComponent as NextIcon } from 'src/assets/wizzard-btn-chev-next.svg';

export const ArrowButton = styled(Button)<{ $wideContent?: boolean }>`
  ${({ $wideContent }) =>
    $wideContent &&
    css`
      padding: 0 14px;
    `};

  .content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
`;

export { NextIcon, PrevIcon };
