import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { Modal } from 'src/components/modals/comps';
import { ArrowButton, NextIcon } from 'src/components/Wizzard/comps';
import styled from 'styled-components';

const Div = styled.div`
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    &__reminder {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

interface IntroProps {
  onClose: () => void;
  onProceed: () => void;
}

export const Intro: React.FC<IntroProps> = ({ onClose, onProceed }) => {
  return (
    <Div>
      <Spacer $px={10} />
      <div className="h-padding">
        <Poppins className="m-title" px={28}>
          Help Us Understand Your Organisation
        </Poppins>
        <Spacer $px={40} />
        <Poppins px={14} color="cflowerBlue">
          To provide accurate risk assessments, we need to gather more about your organisation. Your responses will help
          us suggest relevant risk scenarios, pre-populate possible expected losses, and create variables for reuse
          across multiple scenarios. You can save your answers and exit at any time.
        </Poppins>
        <Spacer $px={120} />
        <div className="btns">
          <Poppins className="btns__reminder" weight={500} onClick={onClose}>
            Remind Me Later
          </Poppins>
          <ArrowButton primary onClick={onProceed} $constWidth={225}>
            <div className="content">
              PROCEED
              <NextIcon />
            </div>
          </ArrowButton>
        </div>
      </div>
    </Div>
  );
};
