import { SlideId } from 'src/api/types/wizard';
import {
  AssessmentDetails,
  Scenarios,
  Organisation,
  Customers,
  SoftwareDev,
  SupplyChain,
  CyberInsurance,
  Regulations,
  Finalise,
} from './comps/slides';

export const standardSlides = [
  {
    title: 'Organisation',
    component: Organisation,
    id: SlideId.organisation,
  },
  {
    title: 'Customers',
    component: Customers,
    id: SlideId.customers,
  },
  {
    title: 'Software Development',
    component: SoftwareDev,
    id: SlideId.softwareDev,
  },
  {
    title: 'Supply Chain',
    component: SupplyChain,
    id: SlideId.supplyChain,
  },
  {
    title: 'Cyber Insurance',
    component: CyberInsurance,
    id: SlideId.cyberInsurance,
  },
  {
    title: 'Regulations and Frameworks',
    component: Regulations,
    id: SlideId.regulations,
  },
  {
    title: 'Assessment Details',
    component: AssessmentDetails,
    id: SlideId.assessmentDetails,
  },
  {
    title: 'Scenarios',
    component: Scenarios,
    id: SlideId.scenarios,
  },
  {
    title: 'Review & Finalise',
    component: Finalise,
    id: SlideId.finalise,
  },
];

export const baseWorkspaceSlides = [
  {
    title: 'Organisation',
    component: Organisation,
    id: SlideId.organisation,
  },
  {
    title: 'Customers',
    component: Customers,
    id: SlideId.customers,
  },
  {
    title: 'Software Development',
    component: SoftwareDev,
    id: SlideId.softwareDev,
  },
  {
    title: 'Supply Chain',
    component: SupplyChain,
    id: SlideId.supplyChain,
  },
  {
    title: 'Cyber Insurance',
    component: CyberInsurance,
    id: SlideId.cyberInsurance,
  },
  {
    title: 'Legislation / Regulations',
    component: Regulations,
    id: SlideId.regulations,
  },
  {
    title: 'Review & Finalise',
    component: Finalise,
    id: SlideId.finalise,
  },
];
