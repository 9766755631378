import React from 'react';
import { Poppins } from 'src/common';
import styled from 'styled-components';
import _ from 'lodash';

const Div = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;

  .vli-column {
    display: flex;
    align-items: flex-start;
    &__value {
      display: flex;
      justify-content: flex-end;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
  }
`;

interface ValueRowSimpleProps {
  title: string;
  value: JSX.Element;
}

export const ValueRowSimple: React.FC<ValueRowSimpleProps> = ({ title, value }) => {
  return (
    <Div>
      <div className="vli-column">
        <Poppins px={14} color="davysGrey">
          {title}
        </Poppins>
      </div>
      <div className="vli-column vli-column__value">{value}</div>
    </Div>
  );
};
