import * as Yup from 'yup';
import { isValid } from 'date-fns';
import { StandardVariableValues } from 'src/hooks/useVariables/types';
import _ from 'lodash';
import { WizardFormValues } from 'src/api/types/wizard';
import { WizardIntent, WizzardWorkspaceType } from 'src/redux/reducers/app/types';
import { FormikErrors } from 'formik';

export const SLIDE_HEIGHT = 400;

const positiveError = 'Value must be greater than 0';

export const initialValues: WizardFormValues = {
  organisation: {
    sector: undefined,
    annualRevenue: undefined, //variable
    numberOfStaff: undefined, //variable
    annualWageBill: undefined, //variable
    avgWorkingDays: 260, //variable
    annualCyberSpend: undefined, //variable
    avgStaffHardwareCost: undefined, //variable
    usesManagedServiceProveder: false,
  },
  customers: {
    numberOfCustomers: undefined, //variable
    avgContractValue: undefined, //variable
    numberOfDataRecords: undefined, //variable
  },
  softwareDev: {
    developsSoftware: false,
    releaseCycleCost: undefined, //variable
    releaseCycleLength: undefined, //variable
    researchAndDevelopmentBudget: undefined, //variable
  },
  supplyChain: {
    avgCostToProcureSupplier: undefined, //variable
    avgInvoiceValue: undefined, //variable
    largestRegularInvoiceValue: undefined, //variable
  },
  cyberInsurance: {
    cyberInsurance: false,
    cyberInsuranceLimit: undefined, //variable
    cyberInsuranceExcess: undefined, //variable
    cyberInsurancePremium: undefined, //variable
  },
  regulations: {
    legistlation: [],
    controlFrameworks: [],
    controlFrameworksOther: [],
    primaryControlFramework: undefined,
    primaryFrameworkControls: [],
  },
  scenarios: {
    B2B: false,
    B2C: false,
    publiclyAccessibleInternetBusiness: false,
    paymentVolumeOrValue: false,
    significantITContributionToRevenue: false,
    significantDataInCloud: false,
    softwareBusiness: false,
    cniOrDefenseInvolvement: false,
    significantRandD: false,
    regulated: false,
    operationalTechnology: false,
    supplyChainReliant: false,
  },
  assessment: {
    name: undefined,
    description: undefined,
    started_date: undefined,
    review_date: undefined,
  },
};

export const validationSchema = Yup.object().shape({
  organisation: Yup.object().shape({
    sector: Yup.string().nullable(),
    annualRevenue: Yup.number().nullable().positive(positiveError), //variable
    numberOfStaff: Yup.number().nullable().positive(positiveError), //variable
    annualWageBill: Yup.number().nullable().positive(positiveError), //variable
    avgWorkingDays: Yup.number().nullable().positive(positiveError), //variable
    annualCyberSpend: Yup.number().nullable().positive(positiveError), //variable
    avgStaffHardwareCost: Yup.number().nullable().positive(positiveError), //variable
    usesManagedServiceProveder: Yup.boolean(),
  }),
  customers: Yup.object().shape({
    numberOfCustomers: Yup.number().nullable().positive(positiveError), //variable
    avgContractValue: Yup.number().nullable().positive(positiveError), //variable
    numberOfDataRecords: Yup.number().nullable().positive(positiveError), //variable
  }),
  softwareDev: Yup.object().shape({
    developsSoftware: Yup.boolean(),
    releaseCycleCost: Yup.number().nullable().positive(positiveError), //variable
    releaseCycleLength: Yup.number().nullable().positive(positiveError), //variable
    researchAndDevelopmentBudget: Yup.number().nullable().positive(positiveError), //variable
  }),
  supplyChain: Yup.object().shape({
    avgCostToProcureSupplier: Yup.number().nullable().positive(positiveError), //variable
    avgInvoiceValue: Yup.number().nullable().positive(positiveError), //variable
    largestRegularInvoiceValue: Yup.number().nullable().positive(positiveError), //variable
  }),
  cyberInsurance: Yup.object().shape({
    cyberInsurance: Yup.boolean(),
    cyberInsuranceLimit: Yup.number().nullable().positive(positiveError), //variable
    cyberInsuranceExcess: Yup.number().nullable().positive(positiveError), //variable
    cyberInsurancePremium: Yup.number().nullable().positive(positiveError), //variable
  }),
  regulations: Yup.object().shape({
    legistlation: Yup.array().of(Yup.string()).nullable(),
    controlFrameworks: Yup.array().of(Yup.string()).nullable(),
    controlFrameworksOther: Yup.array().of(Yup.string()).nullable(),
    primaryControlFramework: Yup.string().nullable(),
    primaryFrameworkControls: Yup.array().of(Yup.string()).nullable(),
  }),
  assessment: Yup.object().shape({
    name: Yup.string().required('Required').nullable(),
    review_date: Yup.string()
      .nullable()
      .test('review_date', 'Invalid Date', (date) => {
        if (typeof date === 'string') return isValid(new Date(date));
        return false;
      })
      .required('Required'),
    description: Yup.string().required('Required'),
  }),
});

export const updateInitVariables = (initData: WizardFormValues, standardVariableValues: StandardVariableValues) => {
  if (!_.isNil(standardVariableValues.annualRevenue))
    initData.organisation.annualRevenue = standardVariableValues.annualRevenue;
  if (!_.isNil(standardVariableValues.numberOfStaff))
    initData.organisation.numberOfStaff = standardVariableValues.numberOfStaff;
  if (!_.isNil(standardVariableValues.annualWageBill))
    initData.organisation.annualWageBill = standardVariableValues.annualWageBill;
  if (!_.isNil(standardVariableValues.avgWorkingDays))
    initData.organisation.avgWorkingDays = standardVariableValues.avgWorkingDays;
  if (!_.isNil(standardVariableValues.annualCyberSpend))
    initData.organisation.annualCyberSpend = standardVariableValues.annualCyberSpend;
  if (!_.isNil(standardVariableValues.avgStaffHardwareCost))
    initData.organisation.avgStaffHardwareCost = standardVariableValues.avgStaffHardwareCost;
  if (!_.isNil(standardVariableValues.numberOfCustomers))
    initData.customers.numberOfCustomers = standardVariableValues.numberOfCustomers;
  if (!_.isNil(standardVariableValues.avgContractValue))
    initData.customers.avgContractValue = standardVariableValues.avgContractValue;
  if (!_.isNil(standardVariableValues.numberOfDataRecords))
    initData.customers.numberOfDataRecords = standardVariableValues.numberOfDataRecords;
  if (!_.isNil(standardVariableValues.releaseCycleCost))
    initData.softwareDev.releaseCycleCost = standardVariableValues.releaseCycleCost;
  if (!_.isNil(standardVariableValues.releaseCycleLength))
    initData.softwareDev.releaseCycleLength = standardVariableValues.releaseCycleLength;
  if (!_.isNil(standardVariableValues.researchAndDevelopmentBudget))
    initData.softwareDev.researchAndDevelopmentBudget = standardVariableValues.researchAndDevelopmentBudget;
  if (!_.isNil(standardVariableValues.avgCostToProcureSupplier))
    initData.supplyChain.avgCostToProcureSupplier = standardVariableValues.avgCostToProcureSupplier;
  if (!_.isNil(standardVariableValues.avgInvoiceValue))
    initData.supplyChain.avgInvoiceValue = standardVariableValues.avgInvoiceValue;
  if (!_.isNil(standardVariableValues.largestRegularInvoiceValue))
    initData.supplyChain.largestRegularInvoiceValue = standardVariableValues.largestRegularInvoiceValue;
  if (!_.isNil(standardVariableValues.cyberInsuranceLimit))
    initData.cyberInsurance.cyberInsuranceLimit = standardVariableValues.cyberInsuranceLimit;
  if (!_.isNil(standardVariableValues.cyberInsuranceExcess))
    initData.cyberInsurance.cyberInsuranceExcess = standardVariableValues.cyberInsuranceExcess;
  if (!_.isNil(standardVariableValues.cyberInsurancePremium))
    initData.cyberInsurance.cyberInsurancePremium = standardVariableValues.cyberInsurancePremium;
};

type IsButtonDisabled = (params: {
  wizardWorkspace: WizzardWorkspaceType | null;
  isLastSlide: boolean;
  errors: FormikErrors<WizardFormValues>;
  isValid: boolean;
}) => boolean;

export const isButtonDisabled: IsButtonDisabled = ({ wizardWorkspace, isLastSlide, errors, isValid }) => {
  if (!isLastSlide) return false;
  if (wizardWorkspace?.intent.type === WizardIntent.workspace && !wizardWorkspace.intent.params.createAssessment) {
    return !_.isEmpty(_.omit(errors, 'assessment'));
  }
  return !isValid;
};

type NextButtonLabel = (params: {
  isLastSlide: boolean;
  wizardWorkspace: WizzardWorkspaceType | null;
  isVariablesPopulated: boolean;
}) => string;

export const nextButtonLabel: NextButtonLabel = ({ isLastSlide, wizardWorkspace, isVariablesPopulated }) => {
  if (!isLastSlide) return 'NEXT';

  if (wizardWorkspace?.intent.type === WizardIntent.scenario) return '+ ADD';

  if (
    (wizardWorkspace?.intent.type === WizardIntent.workspace &&
      wizardWorkspace.intent.params.createAssessment &&
      !isVariablesPopulated) ||
    wizardWorkspace?.intent.type === WizardIntent.assessment
  )
    return 'CREATE ASSESSMENT';

  return 'CONFIRM';
};
