import React from 'react';
import { useFormikContext } from 'formik';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import CurrencyInput from 'src/components/form/CurrencyInput';
import _ from 'lodash';
import Input from 'src/components/form/Input';
import { WizardFormValues } from 'src/api/types/wizard';

export const CyberInsurance = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();

  const limitTouched = _.get(touched, 'cyberInsurance.cyberInsuranceLimit');
  const limitError = _.get(errors, 'cyberInsurance.cyberInsuranceLimit');
  const excessTouched = _.get(touched, 'cyberInsurance.cyberInsuranceExcess');
  const excessError = _.get(errors, 'cyberInsurance.cyberInsuranceExcess');
  const premiumTouched = _.get(touched, 'cyberInsurance.cyberInsurancePremium');
  const premiumError = _.get(errors, 'cyberInsurance.cyberInsurancePremium');

  // useEffect(() => {
  //   if (!values.cyberInsurance.cyberInsurance) {
  //     setFieldValue(`cyberInsurance.cyberInsuranceLimit`, undefined);
  //     setFieldValue(`cyberInsurance.cyberInsuranceExcess`, undefined);
  //     setFieldValue(`cyberInsurance.cyberInsurancePremium`, undefined);
  //   }
  // }, [values.cyberInsurance.cyberInsurance]);

  return (
    <GridContainer>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you have cyber insurance?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <Poppins color="davysGrey" px={14}>
            No
          </Poppins>
          <Input
            className="switch"
            type="checkbox"
            checked={values.cyberInsurance.cyberInsurance}
            onChange={(e) => setFieldValue(`cyberInsurance.cyberInsurance`, e.target.checked)}
          />
          <Poppins color="davysGrey" px={14}>
            Yes
          </Poppins>
        </div>
      </div>
      {values.cyberInsurance.cyberInsurance && (
        <>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              Limit of cyber insurance
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.cyberInsurance.cyberInsuranceLimit}
                currency
                onValueChange={(e) => setFieldValue(`cyberInsurance.cyberInsuranceLimit`, e.floatValue)}
                onBlur={() => setFieldTouched(`cyberInsurance.cyberInsuranceLimit`, true)}
                error={Boolean(limitTouched && limitError)}
                helperText={limitTouched && limitError}
                data-cy="cyberInsurance-cyberInsuranceLimit"
              />
            </div>
          </div>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              Excess of cyber insurance
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.cyberInsurance.cyberInsuranceExcess}
                currency
                onValueChange={(e) => setFieldValue(`cyberInsurance.cyberInsuranceExcess`, e.floatValue)}
                onBlur={() => setFieldTouched(`cyberInsurance.cyberInsuranceExcess`, true)}
                error={Boolean(excessTouched && excessError)}
                helperText={excessTouched && excessError}
                data-cy="cyberInsurance-cyberInsuranceExcess"
              />
            </div>
          </div>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              What is your annual cyber insurance premium?
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.cyberInsurance.cyberInsurancePremium}
                currency
                onValueChange={(e) => setFieldValue(`cyberInsurance.cyberInsurancePremium`, e.floatValue)}
                onBlur={() => setFieldTouched(`cyberInsurance.cyberInsurancePremium`, true)}
                error={Boolean(premiumTouched && premiumError)}
                helperText={premiumTouched && premiumError}
                data-cy="cyberInsurance-cyberInsurancePremium"
              />
            </div>
          </div>
        </>
      )}
    </GridContainer>
  );
};
