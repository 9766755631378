import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { NavItem, NavTitle } from './comps';
import UserPanel from './comps/UserPanel';
import { useAuth } from 'src/state/auth';
import { useResizeListener } from 'primereact/hooks';
import _, { isEqual } from 'lodash';
import { Poppins } from 'src/common';
import { useStateSelector } from 'src/redux';
import useBreakpoints from 'src/hooks/useBreakpoints';
import colors from 'src/theme/colors';

const Div = styled.div`
  position: sticky;
  top: 0;
  z-index: 11;

  .container {
    height: 86px;
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    align-items: center;
    filter: drop-shadow(0px 0px 20px rgba(7, 46, 91, 0.15));
    padding-right: 40px;

    .nav-row {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      max-width: calc(100%);
      overflow: hidden;
      overflow-x: auto;

      &__list {
        position: relative;
        display: flex;
        flex: 1;
        display: flex;
        gap: 10px;
      }
    }

    &__end {
      padding-left: 10px;
      display: flex;
      align-items: center;
      gap: 13px;
      grid-gap: 13px;

      &__name {
        max-width: 450px;
        &--bold {
          font-weight: 600;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .container {
      padding-right: 25px;
      height: 56px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .container {
      padding-right: 15px;
      height: 40px;
    }
  }
`;

const measureTextWidth = (text = '', px = 16) => {
  const truncatedText = text.slice(0, 40);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = `600 ${px}px Poppins`;
  const metrics = context.measureText(truncatedText);
  canvas.remove();
  return metrics.width;
};

const HeaderV2 = () => {
  const locationHeader = useStateSelector(({ app }) => app.location.header, isEqual);
  const [isContractMode, setIsContractMode] = useState(false);
  const { user } = useAuth();
  const isMd = useBreakpoints('md');
  const isSm = useBreakpoints('sm');

  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const innerContracted = useRef(0);

  const calcIsContractMode = () => {
    const inner = innerRef.current?.clientWidth || 0;
    const outer = outerRef.current?.clientWidth || 0;

    if (inner > outer) {
      setIsContractMode(true);
      innerContracted.current = inner;
    } else {
      if (inner > innerContracted.current) {
        setIsContractMode(false);
      }
      innerContracted.current = 0;
    }
  };

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: () => calcIsContractMode(),
  });

  useEffect(() => {
    bindWindowResizeListener();
    outerRef.current?.scrollTo({ left: 100000 });
    calcIsContractMode();
    return () => unbindWindowResizeListener();
  }, [locationHeader]);

  return (
    <Div className="shadow">
      <div className="container">
        <div className="nav-row styled-scroll styled-scroll--size-2" ref={outerRef}>
          <div className="nav-row__list" ref={innerRef}>
            {locationHeader.map((el, idx) => {
              const title = el.titleComponent.title;

              return (
                <NavItem
                  key={el.key + idx}
                  path={el.path}
                  titleComponent={
                    <NavTitle
                      title={title}
                      isActive={el.titleComponent.isActive}
                      iconHome={el.titleComponent.iconHome}
                    />
                  }
                  isStart={el.isStart}
                  isNext={el.isNext}
                  onClick={el.onClick}
                  isClear={el.isClear}
                  zIndex={100 - idx}
                  isContractMode={isContractMode}
                  width={
                    measureTextWidth(title.toUpperCase(), isSm ? 12 : isMd ? 13.6 : 16) +
                    ((el.isStart ? 115 : 95) - (isMd ? 40 : 0))
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="container__end">
          {!isSm && (
            <Poppins nowrap className="container__end__name">
              Hello, <span className="container__end__name--bold">{user?.workspace_user_name}</span>
            </Poppins>
          )}
          <UserPanel />
        </div>
      </div>
    </Div>
  );
};

export default HeaderV2;
