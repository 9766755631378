import { Variable } from 'src/api/types';
import { ScopeType, SortState, VariableEdit } from './types';
import _ from 'lodash';
import * as Yup from 'yup';

export const genEmptyVariable = (scope: ScopeType): VariableEdit => {
  return {
    id: Date.now(),
    name: '',
    alias: null,
    // @ts-ignore
    value: undefined,
    currency: false,
    workspace_variable: scope === 'workspace',
    isInUse: [],
    isGlobal: false,
    _isNew: true,
  };
};

export const findChangedVariables = (original: VariableEdit[], modified: VariableEdit[]) => {
  return modified.filter((item) => {
    const originalItem = original.find((o) => o.id === item.id);
    if (!originalItem || item._isNew) {
      return false;
    }

    return (
      item.name !== originalItem.name || item.value !== originalItem.value || item.currency !== originalItem.currency
    );
  });
};

export const categorizeVariables = (
  input: VariableEdit[],
  original: VariableEdit[],
  excludeDeleteNames: string[],
  label?: string,
) => {
  const newVariables = input
    .filter((el) => el._isNew || el._isNewOverwrite)
    .map((el) => _.omit(el, ['_isNew', '_isNewOverwrite', 'id']));

  const changedVariables = findChangedVariables(original, input).map((el) =>
    el.isGlobal ? { ...el, value: _.isNil(el.value) ? 0 : el.value } : el,
  );

  const deletedVariables = original
    .filter((el) => !input.find((v) => v.id === el.id))
    .map((el) => {
      return { ...el, value: null };
    })
    .filter((el) => !excludeDeleteNames.includes(el.name));

  console.log(`categorizeVariables ${label}`, {
    newVariables,
    changedVariables,
    deletedVariables,
  });

  return [...newVariables, ...changedVariables, ...deletedVariables];
};

const uniqueNameTest = {
  name: 'unique-name',
  // @ts-ignore
  test: function () {
    // @ts-ignore
    const { path, from } = this;

    const existingValue = _.get(from[1].value, path)?.toLowerCase()?.trim();
    const values = from[1].value.variables.map((el: any) => el.name?.toLowerCase()?.trim());
    const duplicate = _.filter(values, (v) => v === existingValue).length > 1;

    if (duplicate) {
      const errorMessage = 'Variable name must be unique.';

      // @ts-ignore
      return this.createError({
        path,
        message: errorMessage,
      });
    }
    return true;
  },
  message: 'Name must be unique',
};

const MAX_VARIABLES = 50;

export const validationSchema = Yup.object({
  variables: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string()
          .min(1, 'Name is too short')
          .required('Required')
          .test(uniqueNameTest)
          .matches(/^[a-zA-Z0-9-_()<>|:@?!\s]+$/, 'Contains unsupported characters'),
        value: Yup.number()
          .nullable()
          .when('isGlobal', {
            is: true,
            then: Yup.number().nullable().min(0, 'Value must be equal to or greater than 0').notRequired(),
            otherwise: Yup.number().nullable().min(0, 'Value must be equal to or greater than 0').required('Required'),
          }),
        currency: Yup.boolean().required('Required'),
        isInUse: Yup.array().of(Yup.string()).nullable(),
        workspace_variable: Yup.boolean().required('Required'),
      }),
    )
    .nullable()
    .max(MAX_VARIABLES, `You can add up to ${MAX_VARIABLES} variables`),
});

export const checkIsDirty = (arr1: VariableEdit[], arr2: VariableEdit[]) => {
  const sortedArr1 = _.sortBy(arr1, 'id');
  const sortedArr2 = _.sortBy(arr2, 'id');

  const areEqual = _.isEqual(sortedArr1, sortedArr2);

  return !areEqual;
};

export const sortVariables = (variables: VariableEdit[], sort: SortState) => {
  const [newVariables, existingVariables] = _.partition(variables, (el) => el._isNew);
  const sortedExisting = _.orderBy(
    existingVariables,
    [
      (el) => {
        const value = el[sort.by];
        return typeof value === 'string' ? value.toLowerCase() : value;
      },
    ],
    [sort.isAsc ? 'asc' : 'desc'],
  );
  const sorted = [...sortedExisting, ...newVariables];
  if (!_.isEqual(sorted, variables)) {
    return sorted;
  } else {
    return undefined;
  }
};

export const dummyData: Variable[] = [
  {
    id: 1,
    alias: 'alias',
    currency: false,
    isInUse: [],
    name: 'name',
    value: 5000,
    workspace_variable: false,
    isGlobal: true,
  },
  {
    id: 2,
    alias: 'alias2',
    currency: true,
    isInUse: [],
    name: 'name - cbc',
    value: 105000,
    workspace_variable: true,
    isGlobal: true,
  },
  {
    id: 3,
    alias: 'alias3',
    currency: true,
    isInUse: ['abc'],
    name: 'name - abc',
    value: 20,
    workspace_variable: true,
    isGlobal: true,
  },
];

export const generateUsageMessage = (data: {
  workspace_variable: boolean;
  id: number;
  assessmentNames?: string[];
  scenarioNames?: string[];
}): string => {
  const usageType = data.workspace_variable ? 'assessments' : 'scenarios';
  const names = (data.workspace_variable ? data.assessmentNames : data.scenarioNames) || [];

  return `This variable can't be deleted because it is in use in the following ${usageType}:</br><strong>${(
    _.uniq(names) || []
  ).join(', ')}</strong>`;
};
