import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useAssessment } from 'src/state/assessment';
import { useQuery } from 'react-query';
import { getAssessments } from 'src/api/assessment';
import { useAuth } from 'src/state/auth';
import Meta from 'src/components/Meta';
import { AssessmentCardSkeleton, PageContainer, Spacer } from 'src/common';
import { useGaEventPageView } from '../../utils/gaEvent';
import { AssessmentCard, Empty, Head } from './comps';
import { Assessment, QKeys, SortType } from 'src/api/types';
import _ from 'lodash';
import { TileContainer } from 'src/components/Tile';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { AssessmentModal } from 'src/components/modals';
import { WizardDebug } from 'src/components/Wizzard/WizardDebug';
import { PagNavButton, PagNumberButton } from 'src/common/pagination/PagNavButton';
import { getPageRange } from 'src/utils/misc';

const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .cnt {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .compare {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    width: 100%;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    gap: 10px;
    &__main-btn {
      width: 150px;
    }
  }
`;

const PAGE_LIMIT = 12;
const AssessmentsPage = () => {
  const [sortType, setSortType] = useState<{ value: SortType; dir: 'asc' | 'desc' }>({
    value: SortType.ByCreatedDate,
    dir: 'desc',
  });
  const [compare, setCompare] = useState<string[] | undefined>(undefined);
  const { isOpen, page, setPage } = useAssessment();
  const { nav, userFeatures } = useAuth();
  const offset = (page - 1) * PAGE_LIMIT;
  const [ordered, setOrdered] = useState<Assessment[] | undefined>(undefined);

  const { data, isLoading } = useQuery([QKeys.Assessments], getAssessments);

  useLayoutEffect(() => {
    nav.setNavLevel(nav.orgPath.length);
  }, [nav]);

  useLayoutEffect(() => {
    const ordered = _.orderBy(data, sortType.value, sortType.dir);
    setOrdered(ordered);
    setPage(1);
  }, [sortType, data]);

  useGaEventPageView('Cydea | Risk Assessments');

  const { start, end } = getPageRange(page, (data?.length || 1) / PAGE_LIMIT, 100);

  return (
    <>
      <Meta title={`Cydea | Risk Assessments`} feedbackScript />
      {userFeatures.app.wizard && <WizardDebug />}
      {isOpen && <AssessmentModal />}
      {!!ordered && (
        <Div>
          {ordered.length === 0 && !isLoading ? (
            <Empty />
          ) : (
            <>
              <PageContainer data-cy="assessments-page" className="cnt">
                <Head
                  compare={compare}
                  setCompare={setCompare}
                  isLoading={isLoading}
                  sortType={sortType}
                  setSortType={setSortType}
                  ordered={ordered}
                />
                <Spacer $px={20} />
                <TileContainer>
                  {isLoading ? (
                    <>
                      <AssessmentCardSkeleton />
                      <AssessmentCardSkeleton />
                      <AssessmentCardSkeleton />
                    </>
                  ) : (
                    <>
                      {ordered.slice(offset, offset + PAGE_LIMIT).map((el) => (
                        <AssessmentCard
                          key={el.id}
                          assessment={el}
                          isSelected={!!compare?.includes(el.id)}
                          compareMode={!!compare}
                          onSelect={(val) => {
                            if (compare?.includes(val)) {
                              setCompare(compare.filter((el) => el !== val));
                              mpEvent(MPEvents.ButtonClick, {
                                button: 'Compare:card-select',
                                tags: ['ASSESSMENT'],
                              });
                            } else {
                              setCompare([...(compare ?? []), val]);
                              mpEvent(MPEvents.ButtonClick, {
                                button: 'Compare:card-unselect',
                                tags: ['ASSESSMENT'],
                              });
                            }
                          }}
                          disabled={compare?.length === 2 && !compare.includes(el.id)}
                        />
                      ))}
                    </>
                  )}
                </TileContainer>
                {ordered.length > PAGE_LIMIT && (
                  <>
                    <Spacer $px={20} />
                    <div className="pagination">
                      <PagNavButton
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                        className="pagination__main-btn"
                      >
                        {'< Previous'}
                      </PagNavButton>
                      {Array.from({ length: end - start + 2 }).map((_, i) => {
                        const pageIndex = start + i;
                        return (
                          <PagNumberButton
                            key={pageIndex}
                            selected={pageIndex + 1 === page}
                            onClick={() => setPage(pageIndex + 1)}
                          >
                            {pageIndex + 1}
                          </PagNumberButton>
                        );
                      })}
                      <PagNavButton
                        onClick={() => setPage(page + 1)}
                        disabled={page === Math.ceil(ordered.length / PAGE_LIMIT)}
                        className="pagination__main-btn"
                      >
                        {'Next >'}
                      </PagNavButton>
                    </div>
                  </>
                )}
              </PageContainer>
            </>
          )}
        </Div>
      )}
    </>
  );
};

export default AssessmentsPage;
