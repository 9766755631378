import { useFormikContext } from 'formik';
import React from 'react';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import CurrencyInput from 'src/components/form/CurrencyInput';
import _ from 'lodash';
import { WizardFormValues } from 'src/api/types/wizard';

export const SupplyChain = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();

  const avgSupplierOnboardingCostTouched = _.get(touched, 'supplyChain.avgCostToProcureSupplier');
  const avgSupplierOnboardingCostError = _.get(errors, 'supplyChain.avgCostToProcureSupplier');
  const avgValueOfInvoicesPaidTouched = _.get(touched, 'supplyChain.avgInvoiceValue');
  const avgValueOfInvoicesPaidError = _.get(errors, 'supplyChain.avgInvoiceValue');
  const largestRegularInvoiceTouched = _.get(touched, 'supplyChain.largestRegularInvoiceValue');
  const largestRegularInvoiceError = _.get(errors, 'supplyChain.largestRegularInvoiceValue');

  return (
    <GridContainer>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Average cost to procure/onboard a new supplier
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.supplyChain.avgCostToProcureSupplier}
            currency
            onValueChange={(e) => setFieldValue(`supplyChain.avgCostToProcureSupplier`, e.floatValue)}
            onBlur={() => setFieldTouched(`supplyChain.avgCostToProcureSupplier`, true)}
            error={Boolean(avgSupplierOnboardingCostTouched && avgSupplierOnboardingCostError)}
            helperText={avgSupplierOnboardingCostTouched && avgSupplierOnboardingCostError}
            data-cy="supplyChain-avg-supplier-onboarding-cost"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Average value of invoices paid
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.supplyChain.avgInvoiceValue}
            currency
            onValueChange={(e) => setFieldValue(`supplyChain.avgInvoiceValue`, e.floatValue)}
            onBlur={() => setFieldTouched(`supplyChain.avgInvoiceValue`, true)}
            error={Boolean(avgValueOfInvoicesPaidTouched && avgValueOfInvoicesPaidError)}
            helperText={avgValueOfInvoicesPaidTouched && avgValueOfInvoicesPaidError}
            data-cy="supplyChain-avg-value-of-invoices-paid"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Largest regular invoice
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.supplyChain.largestRegularInvoiceValue}
            currency
            onValueChange={(e) => setFieldValue(`supplyChain.largestRegularInvoiceValue`, e.floatValue)}
            onBlur={() => setFieldTouched(`supplyChain.largestRegularInvoiceValue`, true)}
            error={Boolean(largestRegularInvoiceTouched && largestRegularInvoiceError)}
            helperText={largestRegularInvoiceTouched && largestRegularInvoiceError}
            data-cy="supplyChain-largest-regular-invoice"
          />
        </div>
      </div>
    </GridContainer>
  );
};
