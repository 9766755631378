import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { queryCache, useMutation } from 'react-query';
import * as Yup from 'yup';
import { Poppins, Spacer } from 'src/common';
import { Guard } from '../Guard';
import { Modal, ModalSubTitle } from '../comps';
import TabsMenu from '../../TabsMenu';
import { FromCsv, FromLib } from './forms';
import { addTemplatesToAssessment } from 'src/api/scenarioTemplates';
import { createScenario } from 'src/api/scenario';
import { motion } from 'framer-motion';
import ScenarioForm from 'src/components/formComposed/ScenarioForm';
import { scenarioInitialValues, scenarioValidationSchema } from 'src/components/formComposed/ScenarioForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

const StyledModal = styled(Modal)`
  width: 1568px;
  min-height: 200px;
  padding: 0;
  /* min-height: 800px; */
  overflow: hidden;

  .content {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
  }
`;

const schemaLib = Yup.object({
  selected: Yup.array().of(Yup.string()).required('Required'),
});

interface AddRiskScenarioModalProps {
  assessmentId: string;
  assessmentName: string;
  onClose: () => void;
}

export const AddRiskScenarioModal: React.FC<AddRiskScenarioModalProps> = ({
  assessmentId,
  assessmentName,
  onClose,
}) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [activeTab, setActiveTab] = useState<'from_new' | 'from_lib' | 'from_csv'>('from_new');
  const [err, setErr] = useState('');
  const { usersOptios } = useAuth();
  // csv
  const [isScvDirty, setIsScvDirty] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [readyForUpload, setReadyForUpload] = useState(false);

  const onSuccess = () => {
    queryCache.invalidateQueries();
    onClose();
  };

  const onError = (err: any) => {
    setErr(err?.message || 'Something went wrong');
  };

  const [addFromLib] = useMutation(addTemplatesToAssessment, {
    onSuccess,
    onError,
  });

  const [addFromNew] = useMutation(createScenario, {
    onSuccess,
    onError,
  });

  const formikNew = useFormik({
    initialValues: scenarioInitialValues,
    validationSchema: scenarioValidationSchema,

    onSubmit: async (values) => {
      setErr('');
      const lower_formula = String(values.lower_formula).replace(/\s+/g, '');
      const upper_formula = String(values.upper_formula).replace(/\s+/g, '');

      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: values.risk_owner,
      });

      return addFromNew({
        ...values,
        assessment_id: assessmentId,
        lower_formula,
        upper_formula,
        risk_owner: userAdd,
      }).then((el) => mpEvent(MPEvents.ScenarioCreate, { id: el?.id as string, tags: ['SCENARIO'] }));
    },
  });

  const formikLib = useFormik({
    initialValues: {
      selected: [],
    },
    validationSchema: schemaLib,

    onSubmit: async (values) => {
      setErr('');
      return addFromLib({ assessments: [assessmentId], templates: values.selected }).then(() =>
        mpEvent(MPEvents.ScenariosImportedFromLibrary, { templates: values.selected, tags: ['SCENARIO'] }),
      );
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={() => onClose()}
      isOpen={isCloseRequested}
      isDirty={formikLib.dirty || formikNew.dirty || isScvDirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <div className="content">
            <Spacer $px={40} />
            <div className="h-padding">
              <Poppins className="m-title" px={28}>
                Add Risk Scenario
              </Poppins>
              <ModalSubTitle title="ASSESSMENT" value={assessmentName} />
              <Spacer $px={40} />
              <TabsMenu
                tabs={[
                  { label: 'Enter Risk Details', value: 'from_new', dataCy: 'from_new' },
                  { label: 'Import From Library', value: 'from_lib', dataCy: 'from_lib' },
                  { label: 'Import From CSV', value: 'from_csv', dataCy: 'from_csv' },
                ]}
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button:
                      tab === 'from_new'
                        ? 'Tab:Enter Risk Details'
                        : tab === 'from_lib'
                          ? 'Tab:Import From Library'
                          : 'Tab:Import From CSV',
                    modal: 'Scenario add modal',
                    tags: ['SCENARIO'],
                  });
                }}
              />
              <Spacer $px={45} />
            </div>
            {activeTab === 'from_new' && (
              <FormikProvider value={formikNew}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <ScenarioForm
                    buttonTitle="CREATE"
                    assessmentId={assessmentId}
                    cb={() =>
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Create',
                        modal: 'Scenario add modal',
                        tags: ['SCENARIO'],
                      })
                    }
                  />
                </motion.div>
              </FormikProvider>
            )}
            {activeTab === 'from_lib' && (
              <FormikProvider value={formikLib}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <FromLib />
                </motion.div>
              </FormikProvider>
            )}
            {activeTab === 'from_csv' && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                <FromCsv
                  assessmentId={assessmentId}
                  onClose={onClose}
                  setIsScvDirty={setIsScvDirty}
                  state={{ file, setFile, readyForUpload, setReadyForUpload }}
                />
              </motion.div>
            )}

            {!!err && (
              <div className="h-padding" css="text-align: center">
                <Spacer $px={15} />
                <Poppins px={14} color="error">
                  {err}
                </Poppins>
              </div>
            )}
            <Spacer $px={40} />
          </div>
        </StyledModal>
      }
    />
  );
};
