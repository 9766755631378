import styled from 'styled-components';

export const GridContainer = styled.div<{ $large?: boolean }>`
  display: grid;
  grid-gap: ${({ $large }) => ($large ? 16 : 6)}px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      height: 100%;
      padding-top: 8px;
    }
    &__end {
      width: 225px;
      min-width: 225px;

      &--checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-gap: 6px;
      }

      &__add-controls {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .row {
      &__end {
        width: 190px;
        min-width: 190px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .row {
      &__title {
        padding-top: 10px;
      }
      &__end {
        width: 155px;
        min-width: 155px;
      }
    }
  }
`;
