import { useQuery } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { QKeys, Variable } from 'src/api/types';

type UseAssessmentVariables = (a: { assessmentId?: string }) => {
  assessmentVariables: Variable[] | null;
  isAssessmentVariablesLoading: boolean;
};

const useAssessmentVariables: UseAssessmentVariables = ({ assessmentId }) => {
  const { data, isLoading } = useQuery([QKeys.Assessment, { assessmentId }], getAssessment, {
    enabled: !!assessmentId,
  });

  return { assessmentVariables: data?.variables || null, isAssessmentVariablesLoading: isLoading };
};

export default useAssessmentVariables;
