import React, { useEffect, useRef, useState } from 'react';
import { PageControlSkeleton, Poppins, Spacer } from 'src/common';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { BottomPanel, Slide, Stepper } from '../../comps';
import { Intro } from './comps';
import {
  initialValues,
  updateInitVariables,
  SLIDE_HEIGHT,
  validationSchema,
  isButtonDisabled,
  nextButtonLabel,
} from './util';
import { Guard } from 'src/components/modals';
import { useStateSelector } from 'src/redux';
import { StyledModal } from './styled';
import { motion } from 'framer-motion';
import useVariables from 'src/hooks/useVariables';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { baseWorkspaceSlides, standardSlides } from './slides';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { SlideId, WizardFormValues } from 'src/api/types/wizard';
import { useMutation, useQuery } from 'react-query';
import { getWizard, updateWizard } from 'src/api/other';
import { QKeys } from 'src/api/types';
import { useMixPanelTracking } from './useMixPanelTracking';

interface IWizzardWorkspaceModalProps {
  onClose: () => void;
}

export const WizzardWorkspaceModal: React.FC<IWizzardWorkspaceModalProps> = ({ onClose }) => {
  const wizardWorkspace = useStateSelector(({ app }) => app.wizardWorkspace);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [step, setStep] = useState(0);
  const [init, setInit] = useState<WizardFormValues>(initialValues);
  const { standardVariableValues, isLoading: isLoadingVariables, checkIfVariablesPopulated } = useVariables({});
  const [slides, setSlides] = useState<typeof standardSlides>([]);
  const [canRender, setCanRender] = useState(false);
  const [showIntro, setShowIntro] = useState(true);

  const { data, isLoading: isLoadingWizard } = useQuery([QKeys.Wizard], getWizard);
  const [update] = useMutation(updateWizard);

  const isVariablesPopulated = checkIfVariablesPopulated(standardVariableValues, {
    developsSoftware: !!data?.softwareDev.developsSoftware,
    cyberInsurance: !!data?.cyberInsurance.cyberInsurance,
  });

  const isLoading = isLoadingVariables || isLoadingWizard;
  const isLastSlide = step === slides.length - 1;
  const isInitialRender = useRef(true);

  const [sliderRef, slider] = useKeenSlider({
    slideChanged: (slider) => {
      setStep(slider.track.details.abs);
    },
    drag: false,
  });

  const onChangeSlide = (id: SlideId) => {
    const idx = slides.findIndex((el) => el.id === id);
    slider.current?.moveToIdx(idx, true, { duration: 0 });
  };

  useEffect(() => {
    if (!isCloseRequested && isInitialRender.current) {
      setTimeout(() => {
        slider.current?.moveToIdx(step, true, { duration: 0 });
        isInitialRender.current = false;
      }, 0);
    }
  }, [step, isCloseRequested]);

  useEffect(() => {
    if (!isLoading) {
      const initData = _.cloneDeep(data) || initialValues;

      updateInitVariables(initData, standardVariableValues);

      const isVariablesPopulated = checkIfVariablesPopulated(standardVariableValues, {
        developsSoftware: initData.softwareDev.developsSoftware,
        cyberInsurance: initData.cyberInsurance.cyberInsurance,
      });

      if (wizardWorkspace?.intent.type === WizardIntent.workspace) {
        if (wizardWorkspace.intent.params.createAssessment) {
          setSlides(standardSlides);
        } else {
          setSlides(baseWorkspaceSlides);
        }
      }

      if (wizardWorkspace?.intent.type === WizardIntent.assessment) {
        setSlides(standardSlides);
        setTimeout(() => {
          onChangeSlide(isVariablesPopulated ? SlideId.assessmentDetails : SlideId.organisation);
        }, 0);
      }

      if (wizardWorkspace?.intent.type === WizardIntent.scenario) {
        initData.assessment = wizardWorkspace.intent.params.assessmentData;
        setSlides(standardSlides);
        setTimeout(() => {
          onChangeSlide(SlideId.scenarios);
        }, 0);
      }

      setInit(initData);
      setCanRender(true);
      setTimeout(() => {
        isInitialRender.current = false;
      }, 0);
    }
  }, [isLoading, standardVariableValues, wizardWorkspace, data]);

  const formik = useFormik({
    initialValues: init,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (val) => {
      return;
    },
  });

  const { dirty, isSubmitting, handleSubmit, values, errors, touched, resetForm, isValid } = formik;

  useMixPanelTracking({
    canRender,
    slides,
    step,
    isInitialRender,
  });

  const saveProgress = async (close?: boolean) => {
    if (dirty) {
      resetForm({
        values: values,
      });
      await update(values);
    }

    if (close) {
      setIsCloseRequested(true);
    }
  };

  console.log('VALUES', { init, values });
  // console.log('ERRORS', errors);
  // console.log('TOUCHED', touched.assessment);
  // console.log('--------------------------');
  if (!canRender) return null;

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal
          onRequestClose={() => {
            setIsCloseRequested(true);
            isInitialRender.current = true;
          }}
          isOpen
          isWizzard
        >
          <Spacer $px={10} />
          {showIntro ? (
            <Intro onClose={onClose} onProceed={() => setShowIntro(false)} />
          ) : (
            <>
              {!isLoading ? (
                <FormikProvider value={formik}>
                  <motion.div
                    className="wm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div className="wm__top h-padding">
                      <Poppins className="m-title" px={28}>
                        {slides[step]?.title}
                      </Poppins>
                      <Spacer $px={20} />
                      <Stepper slides={slides} step={step} onChangeSlide={onChangeSlide} />
                      <Spacer $px={40} />
                    </div>
                    <div className="wm__content" ref={sliderRef}>
                      {slides.map((el, idx) => {
                        const Cmp = el.component;
                        return (
                          <Slide isCurrent={step === idx} slideHeight={SLIDE_HEIGHT}>
                            <Cmp onChangeSlide={onChangeSlide} />
                          </Slide>
                        );
                      })}
                    </div>
                  </motion.div>
                  <div className="wm__bottom h-padding">
                    <BottomPanel
                      onSave={async () => {
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Save',
                          tags: ['WIZARD'],
                          modal: 'Wizzard modal',
                        });
                        await saveProgress(true);
                      }}
                      btnPrev={
                        step !== 0 && {
                          exe: () => {
                            saveProgress();
                            slider.current?.prev();
                          },
                          icon: true,
                          label: 'PREVIOUS',
                        }
                      }
                      btnNext={{
                        exe: () => {
                          saveProgress();
                          slider.current?.next();
                        },
                        icon: !isLastSlide,
                        label: nextButtonLabel({
                          isLastSlide,
                          wizardWorkspace,
                          isVariablesPopulated,
                        }),
                        disabled: isButtonDisabled({
                          isValid,
                          errors,
                          isLastSlide,
                          wizardWorkspace,
                        }),
                      }}
                    />
                  </div>

                  {error && (
                    <div className="err-container">
                      <Spacer $px={10} />
                      <Poppins px={14} color="error">
                        {error.message || 'Something went wrong'}
                      </Poppins>
                    </div>
                  )}
                </FormikProvider>
              ) : (
                <div className="h-padding">
                  <PageControlSkeleton />
                </div>
              )}
            </>
          )}
          <Spacer $px={40} />
        </StyledModal>
      }
    />
  );
};
