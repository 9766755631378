import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { queryCache, useMutation, useQuery } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { deleteScenario } from 'src/api/scenario';
import { QKeys } from 'src/api/types';
import Meta from 'src/components/Meta';
import { GradientText, PageContainer, PageChartSkeleton, Spacer, PageCard, DataTitle, NoData } from 'src/common';
import Audit from 'src/components/Audit';
import { useGaEventPageView } from '../../utils/gaEvent';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import { LogSwitch } from 'src/components/charts/comps';
import { Head, LegendComposed, ScenarioTableExpandContent, VersionedChart } from './comps';
import useVersion from 'src/hooks/useVersion';
import { ConfirmDeleteModal } from 'src/components/modals';
import ChartBlank from 'src/components/charts/ChartBlank';
import Chart from 'src/components/charts/Chart';
import colors from 'src/theme/colors';
import AddRiskScenario from './comps/AddRiskScenario';
import GenericTable from 'src/components/GenericTable';
import { useColumns } from './useColumns';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { TableIds } from 'src/api/types/misc';
import ExpandCollapse from 'src/common/ExpandCollapse';
import { useAuth } from 'src/state/auth';

const Card = styled(PageCard)`
  .sub-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .exceedance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__end {
      display: flex;
      align-items: center;
      grid-gap: 30px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .exceedance {
      &__end {
        grid-gap: 10px;
      }
    }
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  justify-content: space-between;
  .fc-btns {
    display: flex;
    align-items: center;
    grid-gap: 15px;
  }
`;

const HDivider = styled.div`
  height: 1px;
  background: ${colors.stroke};
  width: 100%;
  position: absolute;
  left: 0;
`;

const AssessmentPage = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [deleteScenarioId, setDeleteScenarioId] = useState<string | undefined>(undefined);
  const { version } = useVersion();
  const isMd = useBreakpoints('md');
  const { setLocationPaths } = useActionsApp();
  const { userFeatures } = useAuth();

  const { data = null, isLoading } = useQuery([QKeys.Assessment, { assessmentId, version }], getAssessment);

  useEffect(() => {
    if (data) {
      setLocationPaths({ assessment: { id: data.id, name: data.name } });
    }
    return () => {
      setLocationPaths({ assessment: undefined });
    };
  }, [data]);

  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: data?.scenarios || [],
    version,
    setDeleteScenarioId,
  });

  useGaEventPageView('Cydea | Assessment');

  const [remove] = useMutation(deleteScenario, {
    onSuccess: () => {
      mpEvent(MPEvents.ScenarioDelete, { id: deleteScenarioId as string, tags: ['SCENARIO'] });
      queryCache.invalidateQueries();
      setDeleteScenarioId(undefined);
    },
  });

  const isEmptyChart = data?.chart.every((el) => el.y === 1 && el.x === 0);
  const allScenariosIncomplete = data?.scenarios.every((el) => el.is_incomplete === 1 || el.is_incomplete === 3);

  if (isLoading)
    return (
      <PageContainer>
        <PageChartSkeleton />
      </PageContainer>
    );

  return (
    <>
      {deleteScenarioId && (
        <ConfirmDeleteModal
          onConfirm={() => remove({ id: deleteScenarioId })}
          onClose={() => setDeleteScenarioId(undefined)}
        />
      )}
      {data && (
        <>
          <Meta title={`Cydea | ${data?.name}`} feedbackScript />
          <PageContainer>
            <Card>
              <Head data={data} isLogarithmic={isLogarithmic} />
              <Spacer $px={20} />
              <HDivider />
              <Spacer $px={20} />
              <div className="exceedance">
                <GradientText>LOSS EXCEEDANCE CURVE</GradientText>
                <div className="exceedance__end">
                  {!!data?.scenarios.length && !isMd && <LegendComposed isLegacyVersion={data.legacy_version} />}
                  <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
                </div>
              </div>
              {!!data?.scenarios.length && isMd && <LegendComposed isLegacyVersion={data.legacy_version} />}
              <Spacer $px={20} />
              {!isEmptyChart && !allScenariosIncomplete ? (
                <>
                  {version ? (
                    <VersionedChart isLogarithmic={isLogarithmic} isLegacyVersion={data.legacy_version} />
                  ) : (
                    <Chart
                      isLogarithmic={isLogarithmic}
                      input={[
                        {
                          data: data?.chart || [],
                          upper: getMinMax(data?.scenarios || []).upper || 1,
                          lower: getMinMax(data?.scenarios || []).lower || 1,
                          name: 'Loss Exceedance',
                          color: colors.gradientPart,
                          fillColor: colors.aliceBlue,
                          legendId: 'legend1',
                        },
                        {
                          data: parseRiskTolerance(data?.risk_tolerance),
                          name: 'Risk Tolerance',
                          type: 'spline',
                          dashStyle: 'ShortDash',
                          legendId: 'leg-rt',
                          fillColor: colors.cflowerBlue,
                          dataLabels: {
                            enabled: false,
                          },
                          color: colors.cflowerBlue,
                          isRiskTolerance: true,
                        },
                      ]}
                    />
                  )}
                </>
              ) : (
                <ChartBlank techIncomplete />
              )}
              <DataTitle>SCOPE</DataTitle>
              {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
              <HDivider />
              <Spacer $px={28} />
              <FiltersContainer>
                <GradientText>Risk Register</GradientText>
                {!version && (
                  <div className="fc-btns">
                    <AddRiskScenario assessmentId={data.id} assessmentName={data.name} />
                  </div>
                )}
              </FiltersContainer>
              <Spacer $px={40} />
              <GenericTable
                tableId={TableIds.assessmentScenarios}
                data={data.scenarios}
                columns={columns}
                GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                expandContent={(scen) => {
                  const data =
                    scen.implementationPlans
                      ?.map((el) => {
                        return { control: el.control, modifiers: el.modifiers };
                      })
                      .filter((el) => el.control && el.control.name) || [];
                  if (!data.length) return null;
                  return <ScenarioTableExpandContent data={data} scenario={scen} />;
                }}
                searchable={['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at']}
                initialSort={[{ id: 'updated_at', desc: true }]}
              />
              {userFeatures.app.audit.report && (
                <Audit query={{ version, assessmentId }} tableId={TableIds.auditAssessment} />
              )}
            </Card>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default AssessmentPage;
