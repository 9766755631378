import * as Yup from 'yup';
import { ScenarioFormValues } from './types';

export const scenarioValidationSchema = Yup.object({
  name: Yup.string().required('Required'),
  source: Yup.number().nullable(),
  event: Yup.number().nullable(),
  consequence: Yup.number().nullable(),
  //   risk_owner: Yup.string().nullable(),
  risk_narrative: Yup.string().nullable(),
  frequency_times: Yup.number().nullable(),
  frequency_years: Yup.number().nullable(),
  // frequency_times: Yup.number().nullable().typeError('Should be number').min(0.001, 'Must be greater than 0'),
  // frequency_years: Yup.number().nullable().typeError('Should be number').min(0.001, 'Must be greater than 0'),
  frequency_note: Yup.string().nullable(),
  upper_formula: Yup.string(),
  lower_formula: Yup.string(),
  note: Yup.string().nullable(),
});

export const scenarioInitialValues: ScenarioFormValues = {
  name: '',
  source: undefined,
  event: undefined,
  consequence: undefined,
  risk_owner: null,
  risk_narrative: '',
  frequency_times: undefined,
  frequency_years: undefined,
  frequency_note: '',
  upper: undefined,
  lower: undefined,
  upper_formula: '',
  lower_formula: '',
  note: '',
  risk_identified_date: null,
  review_date: null,
};
