import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { Modal } from 'src/components/modals/comps';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { getControlLibraries } from 'src/api/controllibraries';
import GenericTable, { TableSheetControlLibrary, useControlLibraryColumns } from 'src/components/GenericTable';
import { TableIds } from 'src/api/types/misc';
import { useFormikContext } from 'formik';
import Button from 'src/components/form/Button';
import { WizardFormValues } from 'src/api/types/wizard';

const StyledModal = styled(Modal)`
  width: 1400px;
  display: flex;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .cm-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .children {
    width: 100%;
  }
`;

interface IControlsModalProps {
  onClose: () => void;
  frameworkId?: string;
}

export const ControlsModal: React.FC<IControlsModalProps> = ({ onClose, frameworkId }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const { setFieldValue, values } = useFormikContext<WizardFormValues>();

  const { data: controlLibs = [], isLoading } = useQuery(
    [QKeys.ControlLibraries, frameworkId],
    () => getControlLibraries(frameworkId as string),
    { enabled: !!frameworkId },
  );

  const { columns, checked, GTColumnSelectAnchorExported, setChecked } = useControlLibraryColumns({
    data: controlLibs,
    isCheckerEnabled: true,
    tableId: TableIds.wizzardOrgModalAddControls,
  });

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  useEffect(() => {
    setChecked(values.regulations.primaryFrameworkControls);
  }, []);

  return (
    <>
      <StyledModal onRequestClose={() => onClose()} isOpen center={false} isWizzard>
        <div className="cm-head">
          <Poppins className="m-title" px={28}>
            Which controls do you have in place?
          </Poppins>
          <Button
            primary
            onClick={() => {
              setFieldValue('regulations.primaryFrameworkControls', selected);
              onClose();
            }}
          >
            DONE
          </Button>
        </div>
        <Spacer $px={40} />
        {!isLoading ? (
          <GenericTable
            tableId={TableIds.wizzardOrgModalAddControls}
            data={controlLibs}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            expandContent={(row) => <TableSheetControlLibrary data={row} />}
            searchable={['frameworkShortname', 'controlLibraryId', 'name', 'FrameworkAuthor']}
            itemHeight={50}
            usePagination={{ pageSize: 20 }}
          />
        ) : (
          <TableSkeleton />
        )}
        <Spacer $px={40} />
      </StyledModal>
    </>
  );
};
