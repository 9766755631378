import React from 'react';
import { useQuery } from 'react-query';
import { getAssessments } from 'src/api/assessment';
import { QKeys } from 'src/api/types';
import useVariables from 'src/hooks/useVariables';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { initialValues } from './Workspace/WizzardWorkspaceModal/util';
import { getWizard } from 'src/api/other';

export const WizardDebug = () => {
  const { setWizardWorkspace } = useActionsApp();
  const { checkIfVariablesPopulated, standardVariableValues } = useVariables({});
  const { data: assessments = [], isLoading: isLoadingAssessments } = useQuery([QKeys.Assessments], getAssessments);
  const { data: wizard, isLoading: isLoadingWizard } = useQuery([QKeys.Wizard], getWizard);

  const isLoading = isLoadingAssessments || isLoadingWizard;

  if (isLoading) return null;

  const handleAppSTartEvent = () => {
    const initData = wizard || initialValues;
    const isVariablesPopulated = checkIfVariablesPopulated(standardVariableValues, {
      developsSoftware: initData.softwareDev.developsSoftware,
      cyberInsurance: initData.cyberInsurance.cyberInsurance,
    });
    const hasAssessment = !!assessments?.length;

    if (isVariablesPopulated && hasAssessment) {
      alert('wizard should not show');
    } else {
      setWizardWorkspace({
        intent: {
          type: WizardIntent.workspace,
          params: {
            createAssessment: !hasAssessment,
          },
        },
      });
    }
  };

  return (
    <div>
      <button onClick={handleAppSTartEvent}>_app start event</button>
      <button
        onClick={() =>
          setWizardWorkspace({
            intent: {
              type: WizardIntent.assessment,
            },
          })
        }
      >
        _assessment
      </button>
      <button
        onClick={() =>
          setWizardWorkspace({
            intent: {
              type: WizardIntent.scenario,
              params: {
                assessmentData: {
                  name: 'name',
                  review_date: '2024-09-01T00:00:00.000000Z',
                  description: 'description',
                  started_date: '2024-09-01T00:00:00.000000Z',
                },
              },
            },
          })
        }
      >
        _scenario
      </button>
    </div>
  );
};
