import React, { useEffect } from 'react';
import { WizzardWorkspaceModal } from './WizzardWorkspaceModal';
import { useAuth } from 'src/state/auth';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import useVariables from 'src/hooks/useVariables';

export const WizzardWorkspace = () => {
  const { userFeatures } = useAuth();
  const wizardWorkspace = useStateSelector(({ app }) => app.wizardWorkspace);
  const { setWizardWorkspace } = useActionsApp();
  const { variables, isLoading, standardVariableValues } = useVariables({});

  useEffect(() => {}, []);

  if (!userFeatures.app.wizard) return null;

  if (wizardWorkspace) return <WizzardWorkspaceModal onClose={() => setWizardWorkspace(null)} />;
  return null;
};
