import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAssessments } from 'src/api/assessment';
import { QKeys, Variable } from 'src/api/types';
import { filter } from 'lodash';
import { NameMapping, VariableEdit } from './types';

type UseMappedNames = (a: { variables: VariableEdit[] }) => {
  scenarioNames: NameMapping;
  assessmentNames: NameMapping;
};

const useMappedNames: UseMappedNames = ({ variables }) => {
  const [scenarioNames, setScenarioNames] = useState<NameMapping>({});
  const [assessmentNames, setAssessmentNames] = useState<NameMapping>({});
  const { data: assessments = [] } = useQuery([QKeys.Assessments], getAssessments);

  useEffect(() => {
    const scenarioNames: NameMapping = {};
    const assessmentNames: NameMapping = {};

    variables.forEach((el: Variable) => {
      const scenarioNamesForVariable = filter(
        el.isInUse.map((id) => {
          return assessments
            ?.map((assessment) => assessment.scenarios)
            .flat()
            .find((scenario) => scenario.id === id)?.name as string;
        }),
        (value) => value !== undefined,
      );
      scenarioNames[el.id] = scenarioNamesForVariable;

      const assessmentNamesForVariable = filter(
        el.isInUse.map((id) => {
          return assessments?.find((assessment) => assessment.scenarios.some((scenario) => scenario.id === id))
            ?.name as string;
        }),
        (value) => value !== undefined,
      );
      assessmentNames[el.id] = assessmentNamesForVariable;
    });

    setScenarioNames(scenarioNames);
    setAssessmentNames(assessmentNames);
  }, [assessments, variables]);

  return { scenarioNames, assessmentNames };
};

export default useMappedNames;
