import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../state/auth';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../utils/auth';
import { makeLogout } from '../../utils/auth';

export const CallbackPage = () => {
  const { isLoading, logout: logoutAuth0, getAccessTokenSilently, error } = useAuth0();
  const { setIsSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !error) {
      getAccessTokenSilently()
        .then((token) => {
          setToken(token);
          setIsSignedIn(true);
          navigate('/', { replace: true });
        })
        .catch(() => {
          fullLogout();
        });
    }
  }, [isLoading, error, getAccessTokenSilently, setIsSignedIn, navigate]);

  const fullLogout = () => {
    makeLogout();
    logoutAuth0({ logoutParams: { returnTo: `${window.location.protocol}//${window.location.host}/auth` } });
  };

  if (error) {
    fullLogout();
    return null;
  }

  return null;
};
