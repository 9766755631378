import { useFormikContext } from 'formik';
import React from 'react';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import CurrencyInput from 'src/components/form/CurrencyInput';
import _ from 'lodash';
import CSelect, { Error } from 'src/components/form/Select';
import CheckBox from 'src/components/form/CheckBox';
import { CompanySector, companySectorOptions, WizardFormValues } from 'src/api/types/wizard';

export const Organisation = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();

  const sectorTouched = _.get(touched, 'organisation.sector');
  const sectorError = _.get(errors, 'organisation.sector');
  const annualRevenueTouched = _.get(touched, 'organisation.annualRevenue');
  const annualRevenueError = _.get(errors, 'organisation.annualRevenue');
  const numberOfStaffTouched = _.get(touched, 'organisation.numberOfStaff');
  const numberOfStaffError = _.get(errors, 'organisation.numberOfStaff');
  const annualWageBillTouched = _.get(touched, 'organisation.annualWageBill');
  const annualWageBillError = _.get(errors, 'organisation.annualWageBill');

  const numberOfWorkingDaysPerYearTouched = _.get(touched, 'organisation.avgWorkingDays');
  const numberOfWorkingDaysPerYearError = _.get(errors, 'organisation.avgWorkingDays');
  const annualCyberSpendTouched = _.get(touched, 'organisation.annualCyberSpend');
  const annualCyberSpendError = _.get(errors, 'organisation.annualCyberSpend');
  const averageCostOfStaffHardwareTouched = _.get(touched, 'organisation.avgStaffHardwareCost');
  const averageCostOfStaffHardwareError = _.get(errors, 'organisation.avgStaffHardwareCost');

  return (
    <GridContainer>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Company sector
        </Poppins>
        <div className="row__end">
          <CSelect
            className="cydea-select compact-input"
            options={_.orderBy(companySectorOptions, 'label')}
            onChange={(o: { value: CompanySector }) => {
              setFieldValue('organisation.sector', o.value);
            }}
            value={companySectorOptions.find((o) => o.value === values.organisation.sector)}
            error={Boolean(sectorTouched && sectorError)}
            onBlur={() => setFieldTouched(`organisation.sector`, true)}
            data-cy="organisation-sector"
          />
          {sectorTouched && sectorError && <Error>{sectorError}</Error>}
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Company annual revenue
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.annualRevenue}
            currency
            onValueChange={(e) => setFieldValue(`organisation.annualRevenue`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.annualRevenue`, true)}
            error={Boolean(annualRevenueTouched && annualRevenueError)}
            helperText={annualRevenueTouched && annualRevenueError}
            data-cy="organisation-annual-revenue"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Number of staff
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.numberOfStaff}
            onValueChange={(e) => setFieldValue(`organisation.numberOfStaff`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.numberOfStaff`, true)}
            error={Boolean(numberOfStaffTouched && numberOfStaffError)}
            helperText={numberOfStaffTouched && numberOfStaffError}
            data-cy="organisation-annual-revenue"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Annual wage bill
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.annualWageBill}
            currency
            onValueChange={(e) => setFieldValue(`organisation.annualWageBill`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.annualWageBill`, true)}
            error={Boolean(annualWageBillTouched && annualWageBillError)}
            helperText={annualWageBillTouched && annualWageBillError}
            data-cy="organisation-annual-wage-bill"
          />
        </div>
      </div>

      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Approximate number of working days per year
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.avgWorkingDays}
            onValueChange={(e) => setFieldValue(`organisation.avgWorkingDays`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.avgWorkingDays`, true)}
            error={Boolean(numberOfWorkingDaysPerYearTouched && numberOfWorkingDaysPerYearError)}
            helperText={numberOfWorkingDaysPerYearTouched && numberOfWorkingDaysPerYearError}
            data-cy="organisation-number-of-working-days-per-year"
          />
        </div>
      </div>

      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Annual cyber spend
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.annualCyberSpend}
            currency
            onValueChange={(e) => setFieldValue(`organisation.annualCyberSpend`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.annualCyberSpend`, true)}
            error={Boolean(annualCyberSpendTouched && annualCyberSpendError)}
            helperText={annualCyberSpendTouched && annualCyberSpendError}
            data-cy="organisation-annual-cyber-spend"
          />
        </div>
      </div>

      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Average cost of staff day-to-day hardware/laptop
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.organisation.avgStaffHardwareCost}
            currency
            onValueChange={(e) => setFieldValue(`organisation.avgStaffHardwareCost`, e.floatValue)}
            onBlur={() => setFieldTouched(`organisation.avgStaffHardwareCost`, true)}
            error={Boolean(averageCostOfStaffHardwareTouched && averageCostOfStaffHardwareError)}
            helperText={averageCostOfStaffHardwareTouched && averageCostOfStaffHardwareError}
            data-cy="organisation-average-cost-of-staff-hardware"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you use a managed service provider?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.organisation.usesManagedServiceProveder}
            onClick={(e) => setFieldValue(`organisation.usesManagedServiceProveder`, e)}
          />
        </div>
      </div>

      {/* <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you use a managed service provider?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <Poppins color="davysGrey" px={14}>
            No
          </Poppins>
          <Input
            className="switch"
            type="checkbox"
            checked={values.organisation.usesManagedServiceProveder}
            onChange={(e) => setFieldValue(`organisation.usesManagedServiceProveder`, e.target.checked)}
          />
          <Poppins color="davysGrey" px={14}>
            Yes
          </Poppins>
        </div>
      </div> */}
    </GridContainer>
  );
};
