import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { formatDate, sanitizeForCSV } from 'src/utils/misc';

export const getCSVData = (scenarios: Scenario[]) => {
  return [
    [
      'Name',
      'ID',
      'Sources',
      'Events',
      'Consequences',
      'Risk Owner',
      'Risk Narrative',
      'Frequency - Times',
      'Frequency - Years',
      'Frequency - Note',
      'Consequence - Lower',
      'Consequence - Upper',
      'Consequence - Note',
      'Expected Loss',
      'Created',
      'Updated',
      'Identified Date',
      'Review Date',
      'Risk Management Strategy',
      'Risk Management Note',
    ],
    ...scenarios.map((el) => [
      sanitizeForCSV(el.name),
      sanitizeForCSV(el.id),
      sanitizeForCSV(riskSourcesNames[el.source]),
      sanitizeForCSV(riskEventsNames[el.event]),
      sanitizeForCSV(riskConsequencesNames[el.consequence]),
      sanitizeForCSV(el.risk_owner?.text),
      sanitizeForCSV(el.risk_narrative),
      sanitizeForCSV(el.frequency_times),
      sanitizeForCSV(el.frequency_years),
      sanitizeForCSV(el.frequency_note),
      sanitizeForCSV(el.lower),
      sanitizeForCSV(el.upper),
      sanitizeForCSV(el.note),
      Math.round(el.expected_loss),
      formatDate(el.created_at),
      formatDate(el.updated_at),
      formatDate(el.risk_identified_date || ''),
      formatDate(el.review_date || ''),
      sanitizeForCSV(el.management_strategy),
      sanitizeForCSV(el.management_note),
    ]),
  ];
};
