import React, { useEffect } from 'react';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { standardSlides } from './slides';

type UseMixPanelTracking = (params: {
  slides: typeof standardSlides;
  step: number;
  canRender: boolean;
  isInitialRender: React.MutableRefObject<boolean>;
}) => void;

export const useMixPanelTracking: UseMixPanelTracking = ({ canRender, slides, step, isInitialRender }) => {
  useEffect(() => {
    if (!isInitialRender.current && canRender) {
      try {
        mpEvent(MPEvents.WizardSlideChanged, {
          slideId: slides[step].id,
        });
      } catch (error) {}
    }
  }, [step, slides, canRender]);

  useEffect(() => {
    mpEvent(MPEvents.WizardOpened, {});
    return () => {
      mpEvent(MPEvents.WizardClosed, {});
    };
  }, []);
};
