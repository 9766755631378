type Key = '__cydea_wizzard-organisation__' | '__cydea_wizzard-assessment__';

export const progressSave = async (values: any, key: Key) => {
  window.localStorage.setItem(key, JSON.stringify(values));
};

export const progressLoad = <T>(key: Key): T | null => {
  const data = window.localStorage.getItem(key);
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

export const progressClear = (key: Key) => {
  window.localStorage.removeItem(key);
};
