import React from 'react';
import { GradientText, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { ReactComponent as PencilIcon } from 'src/assets/pencil.svg';
import { motion } from 'framer-motion';

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .hedit {
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      width: 35px;
      height: 35px;
      left: -10px;
      top: -5px;
      position: absolute;
      z-index: -1;
    }
  }
`;

interface HeaderProps {
  title: string;
  gradient?: boolean;
  onEdit?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, gradient, onEdit }) => {
  return (
    <>
      <Div>
        {gradient ? <GradientText>{title}</GradientText> : <Poppins weight={600}>{title}</Poppins>}
        {onEdit && (
          <motion.div className="hedit" whileHover={{ scale: 1.2 }} onClick={onEdit}>
            <PencilIcon />
          </motion.div>
        )}
      </Div>
      <Spacer $px={24} />
    </>
  );
};
