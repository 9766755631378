import React from 'react';
import { useFormikContext } from 'formik';
import { InputLabel, Spacer } from 'src/common';
import _ from 'lodash';
import MaterialInput from 'src/components/form/MaterialInput';
import MaterialDatePicker from 'src/components/form/MaterialDatePicker';
import { useStateSelector } from 'src/redux';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { WizardFormValues } from 'src/api/types/wizard';

export const AssessmentDetails = () => {
  const { values, handleChange, setFieldValue, handleBlur, touched, errors } = useFormikContext<WizardFormValues>();
  const isScenarioType = useStateSelector(({ app }) => app.wizardWorkspace?.intent.type === WizardIntent.scenario);

  const nameTouched = _.get(touched, 'assessment.name');
  const nameError = _.get(errors, 'assessment.name');
  const descriptionTouched = _.get(touched, 'assessment.description');
  const descriptionError = _.get(errors, 'assessment.description');
  const startedDateTouched = _.get(touched, 'assessment.started_date');
  const startedDateError = _.get(errors, 'assessment.started_date');
  const reviewDateTouched = _.get(touched, 'assessment.review_date');
  const reviewDateError = _.get(errors, 'assessment.review_date');

  return (
    <div>
      <InputLabel>ASSESSMENT NAME</InputLabel>
      <MaterialInput
        id="assessment.name"
        name="assessment.name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.assessment.name}
        placeholder="Name of assessment"
        error={nameTouched && Boolean(nameError)}
        helperText={nameTouched && nameError}
        fullWidth
        disabled={isScenarioType}
        data-cy="assessment-name-input"
      />
      <Spacer $px={20} />
      <InputLabel>ASSESSMENT DESCRIPTION</InputLabel>
      <MaterialInput
        name="assessment.description"
        value={values.assessment.description}
        onChange={handleChange}
        onBlur={handleBlur}
        multiline
        fullWidth
        error={descriptionTouched && Boolean(descriptionError)}
        helperText={descriptionTouched && descriptionError}
        placeholder="Description of assessment"
        minRows={3}
        disabled={isScenarioType}
        data-cy="assessment-description-input"
      />
      <Spacer $px={20} />
      <InputLabel>START DATE</InputLabel>
      <MaterialDatePicker
        value={values.assessment.started_date}
        onChange={(date) => setFieldValue('assessment.started_date', date)}
        disabled={isScenarioType}
        inputProps={{
          placeholder: 'Start date',
          onBlur: handleBlur('assessment.started_date'),
          fullWidth: true,
          error: startedDateTouched && Boolean(startedDateError),
          helperText: startedDateTouched && startedDateError,
          disabled: isScenarioType,
          ['data-cy']: 'assessment-started_date-input',
        }}
      />
      <Spacer $px={20} />
      <InputLabel>REVIEW DATE</InputLabel>
      <MaterialDatePicker
        value={values.assessment.review_date}
        onChange={(date) => setFieldValue('assessment.review_date', date)}
        disabled={isScenarioType}
        inputProps={{
          placeholder: 'Review date',
          onBlur: handleBlur('assessment.review_date'),
          fullWidth: true,
          error: reviewDateTouched && Boolean(reviewDateError),
          helperText: reviewDateTouched && reviewDateError,
          disabled: isScenarioType,
          ['data-cy']: 'assessment-review_date-input',
        }}
        data-cy="assessment-date-input"
      />
    </div>
  );
};
